import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { withTheme } from '@emotion/react';
import { Button, TextInput } from 'clevergig-lib';
import config from '../../config';
import globalConst from '../../constants';
import { withNavigation } from '../../helpers/hooksHelper';
import { getStringFromUrl } from '../../utils/utils';
import { getQueryStringParams } from '../../utils/serialize';
import { loginManagerRequest, loadAuth } from '../../redux/modules/auth';
import { pwManagerConfirmation } from '../../services/validates';
import Preloader from '../../components/Preloader/Preloader';
import LogoHeader from '../../components/Login/LogoHeader';

const mapStateToProps = () => ({});

const dispatchToProps = (dispatch) => ({
  loginManagerRequest: (params) => dispatch(loginManagerRequest(params)),
  loadAuth: () => dispatch(loadAuth()),
});

class ManagerInvitation extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      confirmPsw: '',
      loading: false,
      errors: {},
    };
  }

  /**
   * Set or clear error
   * @param {string} field value name
   * @param {string} msg Error message, if empty, clear error
   */
  handleSetError = (field, msg = '') => this.setState((prevState) => ({
    errors: {
      ...prevState.errors,
      [field]: msg,
    }
  }));

  /**
   * Handle change state value
   * @param {object} event SyntheticEvent
   */
  handleChangeValue = ({ target: { name, value } }) => this.setState({
    [name]: value
  }, () => this.handleSetError(name));

  /**
   * Handle change state value
   * @param {object} event SyntheticEvent
   */
  handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.handleSend();
    }
  };

  handleSend = () => {
    const {
      localization: tr,
      currentLang,
    } = this.context;
    const { location } = this.props;
    const { password, confirmPsw } = this.state;

    const parsed = getQueryStringParams(location.search);
    const token = getStringFromUrl(parsed.invitation_token);

    const params = {};
    params.invitation_token = token;
    params.password = password;
    params.password_confirmation = confirmPsw;

    const errors = pwManagerConfirmation(params, tr);

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    this.setState({ loading: true });
    this.props.loginManagerRequest(params)
      .then(() => {
        this.props.loadAuth()
          .then(({ data }) => {
            const url = (data.attributes.manager_type === globalConst.EXTERNAL_TYPE)
              ? 'planning-shifts'
              : 'dashboard';
            this.props.onNavigate(`/${currentLang}/${url}`);
          });
      }).catch((err) => {
        this.setState({ loading: false });

        const isError = !isEmpty(err.errors) && err.errors[0] && err.errors[0].message;
        const errorMsg = isError || tr.messages.accessDenied;

        this.handleSetError('token', errorMsg);
      });
  };

  render() {
    const { currentLang, localization: tr, } = this.context;
    const { theme } = this.props;
    const {
      loading,
      password,
      confirmPsw,
      errors,
    } = this.state;

    return (
      <div className="guestPage">
        <LogoHeader
          currentLang={currentLang}
        />
        <div className="guestPage__container">
          <div className="guestPage__block">
            <h1 className="guestPage__title">{tr.common.setPassword}</h1>
            <div className="guestPage__form">
              <TextInput
                id="password"
                type="password"
                name="password"
                label={`${tr.form.password} *`}
                wrapCssClass={{
                  marginTop: '15px',
                }}
                inputCssClass={{
                  height: '34px',
                }}
                value={password}
                placeholder={tr.form.password}
                error={errors.password}
                onChange={this.handleChangeValue}
              />
              <TextInput
                id="passwordConfirm"
                type="password"
                name="confirmPsw"
                label={`${tr.form.confirmPwd} *`}
                wrapCssClass={{
                  marginTop: '15px',
                }}
                inputCssClass={{
                  height: '34px',
                }}
                value={confirmPsw}
                placeholder={tr.form.confirmPwd}
                error={errors.confirmPsw}
                onChange={this.handleChangeValue}
                onKeyUp={this.handleKeyUp}
              />
              {errors.token && <span className="guestPage__error">{errors.token}</span>}
            </div>
            <div className="guestPage__action">
              <Button
                className="guestPage__action-btn"
                title={tr.common.setup}
                wrapCssClass={{
                  minWidth: '208px',
                  height: '32px',
                  ...theme.buttonsPrimary,
                }}
                attr={{ 'data-qa': 'setup' }}
                onClick={this.handleSend}
              />
            </div>
          </div>
        </div>
        {loading && (<Preloader isDefault={config.isLogisticForce} />)}
      </div>
    );
  }
}

ManagerInvitation.displayName = 'ManagerInvitation';
ManagerInvitation.contextTypes = {
  currentLang: PropTypes.string.isRequired,
  localization: PropTypes.object.isRequired,
};
ManagerInvitation.propTypes = {
  location: PropTypes.object.isRequired,
  theme: PropTypes.shape({
    buttonsPrimary: PropTypes.object
  }),
  loginManagerRequest: PropTypes.func.isRequired,
  loadAuth: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default withNavigation(withTheme(connect(mapStateToProps, dispatchToProps)(ManagerInvitation)));
